<template>
  <v-card
    v-if="groups"
    class="mx-auto"
  >
    <v-card-text>
      <p class="text-h4 text--primary">
        Grupos
      </p>
      <v-row
        no-gutters
      >
        <v-col
          v-for="(group, index) in groups"
          :key="`group-${index}`"
          cols="12"
          sm="12"
          md="12"
        >
          <v-card
            class="pa-2"
            outlined
            tile
          >
            <v-row
              no-gutters
            >
              <v-col
                cols="12"
                sm="6"
                md="6"
              >
                {{group.ID}}
              </v-col>
              <v-col
                cols="12"
                sm="6"
                md="6"
              >
                {{group.Name}}
              </v-col>
            </v-row>
          </v-card>
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
</template>
<script>
import apiBrightsign from '@/services/apiBrightsign'
export default {
  props: {
    presentationID: {
      type: String,
      requried: true,
    },
  },
  data: () => ({
    loading: false,
    groups: null,
  }),
  watch: {
    presentationID () {
      this.handleGetData()
    },
  },
  mounted () {
    this.handleGetData()
  },
  methods: {
    handleGetData () {
      this.groups = null
      if (!this.presentationID) return
      apiBrightsign.getAll(`/v1/private/presentations/${this.presentationID}/groups`)
        .then(response => {
          this.groups = response
          this.loading = false
        })
        .catch(error => {
          console.log(error)
          this.loading = false
        })
    },
  },
}
</script>

